import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// polyfills
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'picturefill';

import { listen } from "quicklink";

import '../css/app.css'

import * as components from './components'

import {t} from "./utils/filters";

// Import static assets
import './static.assets';

// Set background images lazily
document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

// App main - don't add heavy stuff here.
const main = async() => {
    components.globalJS();
};

// Execute async function
main().then((value) => {
});

// Load required vue stuff when the browser is ready
Promise.all([
    import(/* webpackChunkName: "vue" */ 'vue'),
    import(/* webpackChunkName: "cookie-banner" */ '../vue/CookieBanner/CookieBanner.vue')
]).then(([{default: Vue}, {default: CookieBanner} ]) => {
    Vue.filter('t', t);
    const app = new Vue({
        components: {
            CookieBanner: CookieBanner
        }
    }).$mount('#cookie-banner');
});

// Start quicklink
document.addEventListener('DOMContentLoaded', function () {
    listen({
        ignores: [
            uri => uri.includes('#'),
            /\/admin\/?/,
            (uri, elem) => elem.hasAttribute('noprefetch')
        ]
    });
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
